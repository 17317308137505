import { NgModule, Optional, SkipSelf, isDevMode } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { UiModule } from '../ui/ui.module';
import { PwaCheckUpdateService } from './services/pwa-check-update.service';
import { PwaLogUpdateService } from './services/pwa-log-update.service';
import { PwaPromptUpdateService } from './services/pwa-prompt-update.service';
import { PwaRecoverService } from './services/pwa-recover.service';
import { ChooseLoginModuleDialogComponent } from './components/choose-login-module-dialog/choose-login-module-dialog.component';
import { reducers } from './store/app.state'
import { AuthEffects } from './store/effects/auth.effects';
import { VersionPopupComponent } from './components/version-popup/version-popup.component';


@NgModule({
  declarations: [
    ChooseLoginModuleDialogComponent,
    VersionPopupComponent
  ],
  imports: [
    CommonModule,
    UiModule,
    StoreModule.forRoot(reducers, {}),
    EffectsModule.forRoot(AuthEffects),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode() })
  ],
  providers: [
    PwaCheckUpdateService, PwaLogUpdateService, PwaPromptUpdateService, PwaRecoverService
  ]
})
export class CoreModule {
  /* make sure CoreModule is imported only by one NgModule the AppModule */
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import only in AppModule');
    }
  }
}
