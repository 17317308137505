import { Observable, of, timer } from 'rxjs';
import { PreloadingStrategy, Route } from '@angular/router';
import { mergeMap } from 'rxjs/operators';
import { Injectable } from "@angular/core";

@Injectable()
export class PreloadSelectedModules implements PreloadingStrategy {
    preload(route: Route, load: Function): Observable<any> {
        const loadRoute = (delay: number) => delay
            ? timer(1000).pipe(mergeMap(_ => load()))
            : load();
        // console.log('[Route & Route Data]:', route.path, route.data );
        return route.data && route.data['preload']
            ? loadRoute(route.data['delay'])
            : of(null);
    }
}