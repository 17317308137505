import { createReducer, on, Action } from '@ngrx/store';

import * as AuthActions from '../actions/auth.actions';
import * as storageHelper from '../../helpers/storage-helper';
import { LoggedInModuleType } from '../../../shared/interfaces/logged-in-module.type';
import { User } from '../../../shared/interfaces/user.type';

export enum LoginProcessState {
    NOT_STARTED,
    STARTED,
    FINISHED_SUCCESS,
    FINISHED_FAILED
};

export interface State {
    isAuthenticated: boolean;
    user: User | null;
    accessToken: string | null;
    refreshToken: string | null;
    loggedInModule: LoggedInModuleType | null;
    refreshTokenInProgress: boolean | null;
    errorMessage: string | null;
    loginProcessState: LoginProcessState;
    errorCode: string | null;
}

// set the initial state with localStorage
export const initialState: State = {
    isAuthenticated: storageHelper.getFromLocalStorageWithExpiry('accessToken') !== null && storageHelper.getFromLocalStorageWithExpiry('user') != null,
    accessToken: storageHelper.getFromLocalStorageWithExpiry('accessToken'),
    refreshToken: storageHelper.getFromLocalStorageWithExpiry('refreshToken'),
    loggedInModule: storageHelper.getFromLocalStorageWithExpiry('loggedInModule'),
    refreshTokenInProgress: false,
    user: storageHelper.getFromLocalStorageWithExpiry('user'),
    errorMessage: null,
    loginProcessState: LoginProcessState.NOT_STARTED,
    errorCode: null,
};

const authReducer = createReducer(
    initialState,

    on(AuthActions.login, (state, { email, password }) => ({
        ...state,
        loginProcessState: LoginProcessState.STARTED
    })),

    on(AuthActions.loginSuccess, (state, { accessToken, refreshToken, user, loggedInModule }) => ({
        ...state,
        isAuthenticated: true,
        accessToken,
        refreshToken,
        loggedInModule,
        refreshTokenInProgress: false,
        user,
        loginProcessState: LoginProcessState.FINISHED_SUCCESS,
        errorMessage: null,
        errorCode: null
    })),

    on(AuthActions.refreshTokenInProgress, (state, { isAuthenticated }) => ({
        ...state,
        isAuthenticated,
        refreshTokenInProgress: true,
        errorMessage: null,
        errorCode: null
    })),

    on(AuthActions.refreshTokenSuccess, (state, { accessToken, refreshToken, user }) => ({
        ...state,
        isAuthenticated: true,
        accessToken,
        refreshToken,
        refreshTokenInProgress: false,
        user,
        errorMessage: null,
        errorCode: null
    })),

    on(AuthActions.loginFailure, (state, { error }) => {

        let errorMessage = 'Some Unknown Error Occurred.';
        let errorCode = 'Unknown Error';
        const httpError = error;
        console.log('[AuthReducer] LOGIN_FAILURE:', httpError);

        if (httpError.status >= 400) {
            const errorFromServer = httpError.error;
            if (Array.isArray(errorFromServer) && errorFromServer.length > 0) {
                errorMessage = errorFromServer[0].message;
                errorCode = errorFromServer[0].code;
            }
        } else if (httpError.status < 100) {
            errorMessage = httpError.message;
            errorCode = httpError.statusText;
        }

        return {
            ...state,
            refreshTokenInProgress: false,
            errorMessage,
            errorCode,
            loginProcessState: LoginProcessState.FINISHED_FAILED,
        };
    }),

    on(AuthActions.logout, state => ({
        isAuthenticated: false,
        user: null,
        accessToken: null,
        refreshToken: null,
        loggedInModule: null,
        refreshTokenInProgress: null,
        errorMessage: null,
        errorCode: null,
        loginProcessState: LoginProcessState.NOT_STARTED,
    })),


    on(AuthActions.unAuthorized, state => ({
        isAuthenticated: false,
        user: null,
        accessToken: null,
        refreshToken: null,
        loggedInModule: null,
        refreshTokenInProgress: null,
        errorMessage: null,
        errorCode: null,
        loginProcessState: LoginProcessState.NOT_STARTED,
    })),


    on(AuthActions.updateUser, (state, { user }) => ({
        ...state,
        user,
    })),

    on(AuthActions.noAction, (state) => ({
        ...state
    }))

);

export function reducer(state: State | undefined, action: Action) {
    return authReducer(state, action);
}