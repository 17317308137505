import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-choose-login-module-dialog',
  templateUrl: './choose-login-module-dialog.component.html',
  styleUrl: './choose-login-module-dialog.component.scss'
})
export class ChooseLoginModuleDialogComponent {
  selectedBox!: number;

  constructor(
    public dialogRef: MatDialogRef<ChooseLoginModuleDialogComponent>
  ) { }

  ngOnInit(): void {
  }

  onSelect(ev: number) {
    this.selectedBox = ev;
    setTimeout(() => {
      this.dialogRef.close(ev);
    }, 500);
  }
}
