import { Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { filter } from 'rxjs';

@Injectable()
export class PwaPromptUpdateService {

  constructor(private swUpdate: SwUpdate) {
  }

  init() {
    this.swUpdate.versionUpdates
      .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
      .subscribe(evt => {
        if (this.promptUser(evt)) {
          // Reload the page to update to the latest version.
          document.location.reload();
        }
      });
  }

  promptUser(evt: VersionReadyEvent): boolean {
    console.log('[PWA]: Prompt Update');
    return confirm('New Version Available. Confirm to reload to start using new version.')
  }

}


