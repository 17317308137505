import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';

@Injectable()
export class AddLanguageHeaderInterceptor implements HttpInterceptor {

  constructor(
    private translocoService: TranslocoService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (req.headers.has('lng') || req.headers.has('lang')) {
      return next.handle(req);
    } else {

      const lang = this.translocoService.getActiveLang();
      // console.log('Active Lang:', lang)

      const withLangHeader = req.clone({
        setHeaders: { 'lng': lang }
      });
      return next.handle(withLangHeader);
    }
  }
}
