import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map, switchMap, catchError, tap, mergeMap, exhaustMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Injectable, Inject } from '@angular/core';
import { EMPTY, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';

import * as AuthActions from '@core/store/actions/auth.actions';
import * as fromApp from '../app.state';
import * as storageHelper from '../../helpers/storage-helper'
import { User } from '../../../shared/interfaces/user.type';

import { LoggedInModuleType } from '../../../shared/interfaces/logged-in-module.type';
import { LoginResponse, LoginResponseWithModule } from '../../../shared/interfaces/login-response.type';
import { AuthService } from '../../../../services/auth.service';
import { ChooseLoginModuleDialogComponent } from '../../components/choose-login-module-dialog/choose-login-module-dialog.component';
import { HttpErrorResponse } from '@angular/common/http';
import { HelperService } from '@app/services/helper.service';


@Injectable()
export class AuthEffects {

    constructor(
        private actions$: Actions,
        private authService: AuthService,
        private store: Store<fromApp.AppState>,
        private router: Router,
        public dialog: MatDialog,
        private helper: HelperService
    ) { }

    login$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.login),
            switchMap(payload => this.authService.login(payload.email, payload.password).pipe(
                map((loginResponse: LoginResponse) => {
                    console.log('loginResponse:', loginResponse);
                    // if (loginResponse.user.isInstructor || loginResponse.user.isAdmin || loginResponse.user.isOrgAdmin) {
                    //     return AuthActions.loginAreaChooseDialog(loginResponse);
                    // } else {
                        
                    // }
                    let loggedInModule: LoggedInModuleType;
                    loggedInModule = loginResponse.roles[0];    // When there is only one role, just choose that....
                    return AuthActions.loginSuccess({ ...loginResponse, loggedInModule });
                }),
                catchError((err : HttpErrorResponse) => {
                    console.log('login$ = createEffect: Error:', err);
                    return of(AuthActions.loginFailure({ error: err }))
                })
            ))
        )
    );


    loginSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.loginSuccess),
            tap((resp: LoginResponseWithModule) => {
                console.log('Login_Success [Effect]: payload', resp);
                storageHelper.setToLocalStorageWithExpiry('accessToken', resp.accessToken);
                storageHelper.setToLocalStorageWithExpiry('refreshToken', resp.refreshToken);
                storageHelper.setToLocalStorageWithExpiry('user', resp.user);
                storageHelper.setToLocalStorageWithExpiry('loggedInModule', resp.loggedInModule);

                let redirectUrl: string | null = new URL(window.location.href).searchParams.get('redirectUrl');

                if (redirectUrl && redirectUrl != 'null' && redirectUrl != '' && redirectUrl != '/login') {
                    redirectUrl = decodeURI(redirectUrl);
                    console.log('Redirect Url********:', redirectUrl);
                    this.router.navigateByUrl(redirectUrl);
                } else {
                    console.log('Redirecting To Dashboard as we don\'t have redirectUrl');
                    switch (resp.loggedInModule) {
                        case 'student':
                            this.router.navigate(['/student/dashboard']);
                            break;
                        case 'instructor':
                            this.router.navigate(['/instructor/dashboard']);
                            break;
                        case 'admin':
                        case 'subAdmin':
                            this.router.navigate(['/admin/dashboard']);
                            break;
                        case 'superAdmin':
                            this.router.navigate(['/superadmin/dashboard']);
                            break;
                    }
                }
            }),
        ),
        { dispatch: false }
    );


    loginAreaChooseDialog$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.loginAreaChooseDialog),
            exhaustMap((loginResponse: LoginResponse) => {

                console.log(`loginAreaChooseDialog [Effect] ....`);
                const dialogRef = this.dialog.open(ChooseLoginModuleDialogComponent, {
                    width: "500px",
                    maxWidth: '95vw',
                    panelClass: ['app-simple-confirmation'],
                    data: {}
                });

                // Use the exhaustMap operator to wait for the dialog to be closed
                const modifiedResponse: LoginResponse = JSON.parse(JSON.stringify(loginResponse));

                return dialogRef.afterClosed().pipe(
                    map(result => {
                      console.log('The dialog was closed', result);
                      let loggedInModule: LoggedInModuleType | undefined;
                      if (result === 1) {
                         loggedInModule= loginResponse.roles.filter(r => r != 'student')[0];
                      } else if (result === 2) {
                        loggedInModule = 'student';
                      }

                      if (loggedInModule) {
                          return AuthActions.loginSuccess({...modifiedResponse, loggedInModule});
                      } else {
                        this.helper.showErrorMsg('You have not selected any module. Please try again!', 'Error', 3500);
                        return AuthActions.noAction();
                      }
                    })
                  );
            })
        ),
    );


    updateUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.updateUser),
            tap((userObj: { user: User }) => {
                console.log('updateUser [Effect]: payload:', userObj);
                storageHelper.setToLocalStorageWithExpiry('user', userObj.user);
            }),
        ),
        { dispatch: false }
    );


    refreshTokenInProgress$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.refreshTokenInProgress),
            tap(() => {
                console.log('Refresh_Token_In_Progress [Effect]:');
                // Don't navigate anywhere...
            }),
        ),
        { dispatch: false }
    );

    refreshTokenSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.refreshTokenSuccess),
            tap((loginResponse: LoginResponse) => {
                console.log('Refresh_Token_Success [Effect]: payload', loginResponse);
                storageHelper.setToLocalStorageWithExpiry('accessToken', loginResponse.accessToken);
                storageHelper.setToLocalStorageWithExpiry('refreshToken', loginResponse.refreshToken);
                storageHelper.setToLocalStorageWithExpiry('user', loginResponse.user);
                // Don't navigate anywhere...
            }),
        ),
        { dispatch: false }
    );

    unAuthorized$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.unAuthorized),
            tap(() => {
                console.log('Unauthorized [Effect] Will redirect to login, with currentUrl as Query Params');
                // localStorage.clear();
                storageHelper.removeMultipleKeys(['user', 'accessToken', 'refreshToken', 'loggedInModule']);

                let currentUrl = window.location.pathname;
                console.log('URL Before Redirect to Login:', currentUrl);
                currentUrl = encodeURI(currentUrl);
                console.log('URL Encoded:', currentUrl);

                let loginUrl = '/login';
                if (currentUrl != '/login') {
                    loginUrl = '/login?redirectUrl=' + currentUrl;
                }
                this.router.navigateByUrl(loginUrl);

            }),
        ),
        { dispatch: false }
    );

    logout$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.logout),
            tap(() => {
                console.log(`Logout [Effect] Will redirect to login`);
                storageHelper.removeMultipleKeys(['user', 'accessToken', 'refreshToken', 'loggedInModule']);
                this.router.navigateByUrl('/login');
            }),
        ),
        { dispatch: false }
    );
}
