
// app.states.ts
import { createFeatureSelector, ActionReducerMap } from '@ngrx/store';
import * as fromAuth from '../store/reducers/auth.reducers';

export interface AppState {
  auth: fromAuth.State;
}

export const reducers: ActionReducerMap<AppState> = {
  auth: fromAuth.reducer,
};

// Just a memoization to avoid computation
export const selectAuthState = createFeatureSelector<AppState>('auth');