import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { Translation, TranslocoLoader } from "@ngneat/transloco";
import { SkipBaseUrlHeader } from "./interceptors/correct-url-interceptor";

@Injectable()
export class TranslocoHttpLoader implements TranslocoLoader {

    private http = inject(HttpClient);

    getTranslation(lang: string) {
        return this.http.get<Translation>(`/assets/i18n/${lang}.json`, { headers: { 'X-Skip-Base-Url': SkipBaseUrlHeader } });
    }
}
