<h1 mat-dialog-title>
    Select Components Log Level
    <mat-icon (click)="onClose()" class="app-curser-pointer">clear</mat-icon>
</h1>

<mat-dialog-content appScrolling class="w-100 p-0 m-0" style="max-height: 60vh !important;">
    <div class="example-list w-100">
        <div class="example-box w-100" *ngFor="let item of loggingPreferencesList, let ndx = index" cdkDrag>
            <div class="w-100 d-flex flex-row align-items-center justify-content-between">
                <div class="d-flex flex-row align-items-center">
                    <span>{{item.componentName }}</span>
                </div>
                <mat-radio-group [value]="item.logLevel" (change)="onLogLevelChange(item, $event.value)" aria-label="Select an option" class="app-mat-radio-group-accent">
                    <mat-radio-button [value]="LogLevel.DEBUG" [checked]="item.logLevel == LogLevel.DEBUG "> {{ LogLevel.DEBUG }} </mat-radio-button>
                    <mat-radio-button [value]="LogLevel.WARN" [checked]="item.logLevel == LogLevel.WARN "> {{ LogLevel.WARN }} </mat-radio-button>
                    <mat-radio-button [value]="LogLevel.ERROR" [checked]="item.logLevel == LogLevel.ERROR "> {{ LogLevel.ERROR }} </mat-radio-button>
                    <mat-radio-button [value]="LogLevel.NONE" [checked]="item.logLevel == LogLevel.NONE "> {{ LogLevel.NONE }} </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
    </div>
</mat-dialog-content>

<div mat-dialog-actions>
    <button mat-button type="button" class="text-uppercase app-btn-primary" mat-dialog-close
        [disabled]="loading">OK</button>
</div>