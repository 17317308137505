<div class="auth-container">
    <div class="row auth-main">
        <div class="col-sm-6 px-0 d-none d-sm-block">
            <div class="left-img" style="background-image: url(assets/images/pages/login-banner.jpg);"></div>
        </div>
        <div class="col-sm-6 auth-form-section">
            <div class="form-section m-0">
                <div class="auth-wrapper">
                    <h6 class="welcome-msg"> Welcome to Spice </h6>
                    <p class="auth-signup-text text-muted">Need an account?<a routerLink="/signup" class="sign-up-link">Sign Up</a></p>
                    <h2 class="login-title">Sign in</h2>
                    <form class="validate-form" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                        <div class="row">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
                                <mat-form-field class="example-full-width" appearance="outline">
                                    <mat-label>Username</mat-label>
                                    <input matInput formControlName="username" />
                                    <mat-icon matSuffix class="face-icon">face</mat-icon>
                                    @if (loginForm.get('username')?.hasError('required')) {
                                    <mat-error>
                                        Username is required
                                    </mat-error>
                                    }
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-12col-lg-12 col-md-12 col-sm-12 mb-2">
                                <mat-form-field class="example-full-width" appearance="outline">
                                    <mat-label>Password</mat-label>
                                    <input matInput [type]="isShowPassword ? 'text' : 'password'" formControlName="password">
                                    <a class="show-pwd-icon" href="#" onClick="return false;" matSuffix
                                        (click)="isShowPassword = !isShowPassword" [attr.aria-label]="'Hide password'"
                                        [attr.aria-pressed]="isShowPassword">
                                        <mat-icon matSuffix>{{isShowPassword ? 'visibility' : 'visibility_off'}}</mat-icon>
                                    </a>
                                    @if (loginForm.get('password')?.hasError('required')) {
                                    <mat-error>
                                        Password is required
                                    </mat-error>
                                    }
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between align-items-center mb-5">
                            <div class="form-check">
                                <label class="form-check-label">
                                    <input class="form-check-input" type="checkbox" value=""> Remember me
                                    <span class="form-check-sign">
                                        <span class="check"></span>
                                    </span>
                                </label>
                            </div>
                            <a class="txt1" routerLink="/forgot-password">Forgot Password?</a>
                        </div>
                        @if (error) {
                        <div class="alert alert-danger mt-3 mb-0">{{error}}</div>
                        }
                        <div class="container-auth-form-btn">
                            <div style="text-align:center">
                                <button mat-flat-button color="accent" class="auth-form-btn" [disabled]="!loginForm.valid " type="submit">
                                    Login
                                    <i class="fas fa-spinner fa-spin" *ngIf="formLoading"></i>
                                </button>
                            </div>
                        </div>
                    </form>
                    <h6 class="social-login-title">OR</h6>
                    <ul class="list-unstyled social-icon mb-0 mt-3">
                        <li class="list-inline-item"><a href="javascript:void(0)" class="rounded"><i class="fab fa-google"></i></a></li>
                        <li class="list-inline-item"><a href="javascript:void(0)" class="rounded flex-c-m"><i class="fab fa-facebook-f"></i></a></li>
                        <li class="list-inline-item"><a href="javascript:void(0)" class="rounded"><i class="fab fa-twitter"></i></a></li>
                        <li class="list-inline-item"><a href="javascript:void(0)" class="rounded"><i class="fab fa-linkedin-in"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>



<!-- <div class="app-login-page">
    <div class="app-login-container">
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
            <div class="app-login-header">
                <img alt="logo2" src="https://pg888-resources.s3.ap-southeast-1.amazonaws.com/organizationLogos/c149be98-4edb-4682-bfed-fb7ddfdd4049.png">
                <div class="app-language-selection">
                    <span class="text-sm app-text-primary">Select Language</span>
                    <ul>
                        <li>
                            <a id="engLang" class="eng_font app-curser-pointer">
                                <img src="/assets/images/icons/en.png" alt="" class="mr-2 w-7 h-7 rounded-full">
                            </a>
                        </li>
                        <li>
                            <a id="thLang" class="thai_font app-curser-pointer">
                                <img src="/assets/images/icons/th.png" alt="" class="mr-2 w-7 h-7 rounded-full">
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="app-login-body">
                <mat-form-field class="app-mat-form-field" appearance="outline">
                    <mat-label>Email/Mobile/Username</mat-label>
                    <input matInput placeholder="Email/Mobile/Username" formControlName="username">
                    @for (validation of validationMessages.username; track validation.type) {
                        <mat-error *ngIf="loginForm.get('username')?.hasError(validation.type) && (loginForm.get('username')?.dirty || loginForm.get('username')?.touched)">{{validation.message}}</mat-error>
                    }
                </mat-form-field>
                <mat-form-field class="app-mat-form-field" appearance="outline">
                    <mat-label>Password</mat-label>
                    <input [type]="isShowPassword? 'text' : 'password'" matInput placeholder="Enter your password" formControlName="password">
                    <button matSuffix mat-icon-button type="button" (click)="isShowPassword=!isShowPassword">
                        @if(isShowPassword){
                            <mat-icon>visibility</mat-icon>
                        }
                        @if(!isShowPassword){
                            <mat-icon>visibility_off</mat-icon>
                        }
                    </button>
                    @for (validation of validationMessages.password; track validation.type) {
                        <mat-error *ngIf="loginForm.get('password')?.hasError(validation.type) && (loginForm.get('password')?.dirty || loginForm.get('password')?.touched)">
                            {{validation.message}}
                        </mat-error>
                    }
                </mat-form-field>
            </div>
            <div class="app-login-footer">
                <ul>
                    <li>
                        <a class="app-a" [routerLink]="['/forget-password']">Forgot Password</a>
                    </li>
                </ul>
                <div class="app-footer-action">
                    <button mat-flat-button class="app-btn-filled-primary w-100">
                        Login
                        <i class="fas fa-spinner fa-spin" *ngIf="formLoading"></i>
                    </button>
                    <h2 class="app-or">
                        <div>OR</div>
                    </h2>
                    <button (click)="attemptLineLogin()" mat-flat-button type="button" class="app-btn-filled-primary w-100 app-btn-line">
                        <div class="d-flex align-items-center">
                            <div class="px-2 mr-2 border-r-slate-50 flex">
                                <img src="assets/icons/line.png" width="25" alt="line">
                            </div>
                            Login With Line
                        </div>
                    </button>
                </div>
            </div>
        </form>
    </div>
</div> -->