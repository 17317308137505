import { Component } from '@angular/core';
import { FormControl, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';

import { Store } from '@ngrx/store';
import * as AuthActions from '@core/store/actions/auth.actions';
import * as fromApp from '@core/store/app.state';
import { LoginProcessState } from '@core/store/reducers/auth.reducers';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {

  constructor(
    private store: Store<fromApp.AppState>,
    private snackbar: MatSnackBar
  ) {

    this.store.select('auth').subscribe((authState) => {
      if (authState.loginProcessState == LoginProcessState.STARTED) {
        this.formLoading = true;
      } else if (authState.loginProcessState == LoginProcessState.FINISHED_SUCCESS) {
        this.formLoading = false;
      } else if (authState.loginProcessState == LoginProcessState.FINISHED_FAILED) {
        this.formLoading = false;
        this.snackbar.open(authState.errorMessage!, 'Login Error', { duration: 3500 });
      }
    });
  }
  
  isShowPassword = false;
  formLoading: boolean = false;
  error = '';
  loginForm = new FormGroup({
    username: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
  });

  validationMessages = {
    username: [
      { type: 'required', message: 'Email/Mobile/Username is required' },
    ],
    password: [
      { type: 'required', message: `Password is required` },
    ],
  }

  ngOnInit() {
  }

  onSubmit() {
    console.log('onSubmit: ', this.loginForm.value);

    if(this.loginForm.valid) {
      this.formLoading = true;

      let payload = this.loginForm.value as Partial<{ username: string; password: string; }>;
      this.store.dispatch(AuthActions.login({ email: payload.username!, password: payload.password! }));
    }

  }


  attemptLineLogin() {
    console.log("Attempting line login clicked");

    console.log('Redirecting to Line Login...');

    const RESPONSE_TYPE = 'code';
    const CLIENT_ID = '1654799092';
    const REDIRECT_URI = window.location.origin + '/line-auth-callback';
    const STATE = new Date().getTime() + '-servicemind-login';
    const SCOPE = 'profile%20openid%20email';
    const NONCE = '0921servicemind';

    console.log('Redirect URI:', REDIRECT_URI);

    window.location.href = `https://access.line.me/oauth2/v2.1/authorize?response_type=${RESPONSE_TYPE}&client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&state=${STATE}&scope=${SCOPE}&nonce=${NONCE}`;
  }

}
