import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';

@Injectable()
export class PwaRecoverService {

  constructor(private updates: SwUpdate) {
  }

  init() {
    this.updates.unrecoverable.subscribe(event => {
      this.notifyUser(
        'An error occurred that we cannot recover from:\n' +
        event.reason +
        '\n\nReloading the page.'
      );
    });
  }


  notifyUser(message: string) {
    alert(message);
    document.location.reload();
  }
}
