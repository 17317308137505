import { Component, HostListener, OnInit, inject, isDevMode } from '@angular/core';
import { SocketService } from './services/socket.service';
import { Title } from '@angular/platform-browser';
import { environment as env } from '../environments/environment';
import { TranslocoService, getBrowserLang } from '@ngneat/transloco';
import * as storageHelper from '@core/helpers/storage-helper';
import { PwaCheckUpdateService } from '@core/services/pwa-check-update.service';
import { PwaLogUpdateService } from '@core/services/pwa-log-update.service';
import { PwaPromptUpdateService } from '@core/services/pwa-prompt-update.service';
import { PwaRecoverService } from '@core/services/pwa-recover.service';
import { LogLevel, LoggingService } from './services/logging.service';
import { MatDialog } from '@angular/material/dialog';
import { LoggingSetupDialogComponent } from './components/logging-setup-dialog/logging-setup-dialog.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  static readonly COMPONENT_NAME = 'AppComponent';

  socketService = inject(SocketService);
  titleService = inject(Title);
  translocoService = inject(TranslocoService);
  pwaCheckUpdateService = inject(PwaCheckUpdateService);
  pwaLogUpdateService = inject(PwaLogUpdateService);
  pwaPromptUpdateService = inject(PwaPromptUpdateService);
  pwaRecoverService = inject(PwaRecoverService);
  dialog = inject(MatDialog);
  loggingService = inject(LoggingService);

  canSaveLanguageChange = false;

  showLoggingSetupButton = false;

  ngOnInit(): void {
    this.titleService.setTitle(`Cantrak (v${env.appVersion})`);

    this.socketService.init();

    this.setUpLanguage();

    window.addEventListener("load", function () {
      setTimeout(function () {
        // Hide the address bar!
        window.scrollTo(0, 1);
      }, 0);
    });

    if (!env.production) {
      this.showLoggingSetupButton = true;
    } else {
      this.showLoggingSetupButton = false;
    }
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    // Check if Alt + m is pressed
    if (event.altKey && event.key === 'm') {
      // Wait for 'l' key press
      const waitForG = (e: KeyboardEvent) => {
        if (e.key === 'l') {
          this.showLoggingSetupButton = !this.showLoggingSetupButton; // Toggle the button visibility
          window.removeEventListener('keydown', waitForG); // Remove the listener
        }
      };

      window.addEventListener('keydown', waitForG);
    }
  }

  @HostListener("window:visibilitychange", ["$event"])
  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    this.socketService.verifyConnectionStatus();
  }


  setUpLanguage() {
    this.translocoService.langChanges$.subscribe(lang => {
      if (this.canSaveLanguageChange) {
        this.loggingService.log(LogLevel.DEBUG, AppComponent.COMPONENT_NAME, 'App Language Changed: Saving...', lang);
        // Do not modify, until first attempt to read from localStorage is made...
        storageHelper.setToLocalStorageWithExpiry('user_preferred_language', lang, 604800);
      }
    });

    // Check if user have selected different preferred language..
    let userLang = storageHelper.getFromLocalStorageWithExpiry('user_preferred_language');
    if (!userLang || userLang == '') {
      userLang = getBrowserLang();
    }

    this.translocoService.setActiveLang(userLang);  // Setting the default browser language..
    this.canSaveLanguageChange = true;
  }



  setPwaServices() {
    if (!isDevMode()) {
      this.pwaCheckUpdateService.init();
      this.pwaLogUpdateService.init();
      this.pwaPromptUpdateService.init();
      this.pwaRecoverService.init();
    }
  }


  openLoggingSettingsUiDialog() {
    const dialogRef = this.dialog.open(LoggingSetupDialogComponent, {
      width: "50%",
      panelClass: ['app-simple-confirmation'],
      data: {},
      disableClose: false,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.loggingService.log(LogLevel.DEBUG, AppComponent.COMPONENT_NAME, 'The LoggingSetupDialogComponent dialog was closed with result:', result);
    });
  }



}
