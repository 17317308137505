import { NgModule, isDevMode } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PreloadSelectedModules } from './preload-selected-modules';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { LoginComponent } from './components/login/login.component';
import { GoogleAuthComponent } from './components/google-auth/google-auth.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { adminGuard } from '@core/guards/admin.guard';
import { superadminGuard } from '@core/guards/superadmin.guard';
import { RegistrationComponent } from './components/registration/registration.component';

const routes: Routes = [

  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent, data: { showHeader: false, showFooter: false } },
  { path: 'signup', component: RegistrationComponent, data: { showHeader: false, showFooter: false } },
  { path: 'google-auth-callback', component: GoogleAuthComponent, data: { showHeader: false, showFooter: false } },
  { path: 'forgot-password', component: LoginComponent, data: { showHeader: false, showFooter: false, isForget: true } },
  { path: 'reset-password/:uuid', component: ResetPasswordComponent },
  { path: 'verify-account/:uuid', component: LoginComponent },
  {
    path: 'admin', loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule),
    canActivate: [adminGuard], data: { preload: true }
  },
  {
    path: 'superadmin', loadChildren: () => import('./modules/superadmin/superadmin.module').then(m => m.SuperadminModule),
    canActivate: [superadminGuard], data: { preload: true, delay: 2000 }
  },
  { path: 'not-found', pathMatch: 'full', component: NotFoundComponent, data: { showHeader: false, showFooter: false } },
  { path: '**', pathMatch: 'full', redirectTo: '/not-found' },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes,
      {
        useHash: false,
        preloadingStrategy: !isDevMode() ? PreloadSelectedModules : null,
        scrollPositionRestoration: 'enabled'
      }
    ),
  ],
  exports: [RouterModule],
  providers: [PreloadSelectedModules],
})
export class AppRoutingModule { }
