import { Component, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HelperService } from '@app/services/helper.service';
import { LogLevel, LoggingService } from '@app/services/logging.service';

@Component({
  selector: 'app-logging-setup-dialog',
  templateUrl: './logging-setup-dialog.component.html',
  styleUrl: './logging-setup-dialog.component.scss'
})
export class LoggingSetupDialogComponent {
  static readonly COMPONENT_NAME = 'LoggingSetupDialogComponent';

  LogLevel = LogLevel; // Expose the enum to the template

  loading: boolean = false;
  updatingTemplate: boolean = false;
  savingTemplate: boolean = false;
  loggingPreferencesList: { componentName: string, logLevel: LogLevel }[] = [];

  constructor(
    public dialogRef: MatDialogRef<LoggingSetupDialogComponent>,
    private helper: HelperService, 
    private snackBar: MatSnackBar,
    private loggingService: LoggingService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
  }

  ngOnInit(): void {
    this.loggingPreferencesList = this.loggingService.getLoggingPreferences();
  }

  async onLogLevelChange(preference: { componentName: string, logLevel: LogLevel }, newLogLevel: LogLevel) {
    this.loggingService.log(LogLevel.DEBUG, LoggingSetupDialogComponent.COMPONENT_NAME, `Updating ${preference.componentName}'s Log Level to: `, newLogLevel);
    preference.logLevel = newLogLevel;
    await this.loggingService.updateLoggingPreferenceForComponent(preference.componentName, newLogLevel);
    this.helper.showSuccessMsg(`Updated ${preference.componentName}'s Log Level to: ${preference.logLevel}`, 'Success', 3500);
  }

  onClose(data: any = null){
    this.dialogRef.close(data);
  }
}
