import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class AddTimeZoneInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (req.headers.has('timezone')) {
      return next.handle(req);
    } else {
      let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const withLangHeader = req.clone({
        setHeaders: { 'timezone': timezone }
      });
      return next.handle(withLangHeader);
    }
  }
}
