import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { Component, Inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AppState } from '@core/store/app.state';
import { LoggedInModuleType } from '@app/modules/shared/interfaces/logged-in-module.type';
import { LoginResponse } from '@app/modules/shared/interfaces/login-response.type';
import { AuthService } from '@app/services/auth.service';
import { Store } from '@ngrx/store';
import { Observable, map, shareReplay } from 'rxjs';
import { environment as env } from 'environments/environment';
import * as AuthActions from '@core/store/actions/auth.actions';
import * as fromAuth from '@core/store/reducers/auth.reducers';

@Component({
  selector: 'app-google-auth',
  templateUrl: './google-auth.component.html',
  styleUrl: './google-auth.component.scss'
})
export class GoogleAuthComponent {
  errorMessage = '';
  loaded: any;
  s3Url = '';
  isMobile: boolean = false;
  loginResponse!: LoginResponse;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.Handset, Breakpoints.TabletPortrait])
    .pipe(
      map(result => {
        console.log("== result ==", result);
        return result.matches;
      }),
      shareReplay()
    );

  constructor(
    private breakpointObserver: BreakpointObserver,
    private route: ActivatedRoute, private router: Router,
    private store: Store<AppState>,
    private authService: AuthService, private snackBar: MatSnackBar) {
    this.s3Url = env?.bucketUrl;
  }

  ngOnInit(): void {
    this.isHandset$.subscribe(r => {
      console.log('IS HANDSET:', r);
      this.isMobile = r;
    });

    this.store.select('auth').subscribe((authState: fromAuth.State) => {
      console.log('Current AuthState:', authState);
      if(authState?.accessToken && authState?.refreshToken && authState?.user && authState?.loggedInModule){
        this.loginResponse = { accessToken: authState.accessToken, refreshToken: authState.refreshToken, user: authState.user, roles: [authState.loggedInModule] };
      }
    });

    this.route.queryParams.subscribe((qp) => {
      const ORIGIN_URL = window.location.origin;
      console.log(ORIGIN_URL)
      if (qp['error'] || qp['error_description']) {
        this.errorMessage = qp['error'];
      } else {
        // Now send this auth code to backend for completing Google authorization ....          
        this.authService.authorizeGoogleAccount({ code: qp['code'], state: qp['state'] }).subscribe(response => {
          if (response.data.isAuthorizedSuccessfully) {
            this.snackBar.open('GOOGLE account linked to you Cantrak account successfully!', 'Success', {
              duration: 4000
            });
            // Dispatch the update user in the store...
            this.loginResponse.user = { ...this.loginResponse.user, ...response.data.user };
            console.log('Updated Login Object:', this.loginResponse);

            console.log('loginResponse:', this.loginResponse);
            if (this.loginResponse.user.isInstructor || this.loginResponse.user.isStudent || this.loginResponse.user.isAdmin || this.loginResponse.user.isSubAdmin) {
              this.store.dispatch(AuthActions.loginAreaChooseDialog(this.loginResponse));
            } else {
              let loggedInModule: LoggedInModuleType;
              loggedInModule = this.loginResponse.roles[0];    // When there is only one role, just choose that....
              this.store.dispatch(AuthActions.loginSuccess({ ...this.loginResponse, loggedInModule }));
            }

          } else if (!response.data.isAuthorizedSuccessfully && response.data.user && response.data.message) {
            const snackBarRef = this.snackBar.open(response.data.message, 'Try again', {
              duration: 6000
            });
            // Dispatch the update user in the store...
            this.loginResponse.user = { ...this.loginResponse.user, ...response.data.user };
            console.log('Updated Login Object:', this.loginResponse);

            console.log('loginResponse:', this.loginResponse);
            if (this.loginResponse.user.isInstructor || this.loginResponse.user.isStudent || this.loginResponse.user.isAdmin || this.loginResponse.user.isSubAdmin) {
              this.store.dispatch(AuthActions.loginAreaChooseDialog(this.loginResponse));
            } else {
              let loggedInModule: LoggedInModuleType;
              loggedInModule = this.loginResponse.roles[0];    // When there is only one role, just choose that....
              this.store.dispatch(AuthActions.loginSuccess({ ...this.loginResponse, loggedInModule }));
            }

            snackBarRef.onAction().subscribe(() => {
              this.connectGoogleAccount();
            })
          } else {
            console.log('Authorization Error:', response.data.error);
            this.errorMessage = 'Authorization Error';
          }
        }, err => {
          console.log('Some error occurred');
        });
      }
    });
  }

  connectGoogleAccount() {
    console.log('Redirecting to Google outh...');

    const STATE = "google-cantrak-connect";

    const SCOPE = "https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/calendar.app.created https://www.googleapis.com/auth/userinfo.email openid";

    const REDIRECT_URI = window.location.origin + "/google-auth-callback";

    const CLIENT_ID = env?.googleClientId;

    window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?scope=${SCOPE}&access_type=offline&include_granted_scopes=true&response_type=code&state=${STATE}&redirect_uri=${REDIRECT_URI}&client_id=${CLIENT_ID}`
  }
}
