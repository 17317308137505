import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';

@Injectable()
export class PwaLogUpdateService {

  constructor(private updates: SwUpdate) {
    
  }

  init() {
    this.updates.versionUpdates.subscribe(evt => {
      switch (evt.type) {
        case 'VERSION_DETECTED':
          console.log(`[PWA]: Downloading new app version: ${evt.version.hash}`);
          break;
        case 'VERSION_READY':
          console.log(`[PWA]: Current app version: ${evt.currentVersion.hash}`);
          console.log(`[PWA]: New app version ready for use: ${evt.latestVersion.hash}`);
          break;
        case 'VERSION_INSTALLATION_FAILED':
          console.log(`[PWA]: Failed to install app version '${evt.version.hash}': ${evt.error}`);
          break;
      }
    });
  }

}
