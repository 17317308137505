import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';


// Save the original console functions for internal use in the LoggingService
(window as any).__originalConsoleLog = console.log;
(window as any).__originalConsoleError = console.error;
(window as any).__originalConsoleWarn = console.warn;

// Override console.log, console.error, console.warn
console.log = function(...args: any[]) {
  // (window as any).__originalConsoleWarn("Direct usage of console.log is deprecated. Use LoggingService instead.");
  // (window as any).__originalConsoleLog.apply(console, args);
};

console.error = function(...args: any[]) {
  // (window as any).__originalConsoleWarn("Direct usage of console.error is deprecated. Use LoggingService instead.");
  (window as any).__originalConsoleError.apply(console, args);
};

console.warn = function(...args: any[]) {
  (window as any).__originalConsoleWarn("Direct usage of console.warn is deprecated. Use LoggingService instead.");
  (window as any).__originalConsoleWarn.apply(console, args);
};


platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));