import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { first, interval, concat } from 'rxjs';

@Injectable()
export class PwaCheckUpdateService {

  constructor(private appRef: ApplicationRef, private updates: SwUpdate) {
  }

  public init() {
    // Allow the app to stabilize first, before starting
    // polling for updates with `interval()`.
    const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable === true));
    const everySixMins$ = interval(6 * 60 * 1000);
    const everySixMinsOnceAppIsStable$ = concat(appIsStable$, everySixMins$);

    everySixMinsOnceAppIsStable$.subscribe(async () => {
      try {
        const updateFound = await this.updates.checkForUpdate();
        console.log(updateFound ? '[PWA]: A new version is available.' : '[PWA]: Already on the latest version.');
      } catch (err) {
        console.error('[PWA]: Failed to check for updates:', err);
      }
    });
  }

}
