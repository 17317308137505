<div class="auth-container">
    <div class="row auth-main">
      <div class="col-sm-6 px-0 d-none d-sm-block">
        <div class="left-img" style="background-image: url(assets/images/pages/welcome-banner.jpg);">
        </div>
      </div>
      <div class="col-sm-6 auth-form-section">
        <div class="form-section m-0">
          <div class="auth-wrapper">
            <h6 class="welcome-msg"> Sign Up </h6>
            <p class="auth-signup-text text-muted">Enter details to create your account</p>
            <form class="validate-form" [formGroup]="form" (ngSubmit)="onSubmit()">
              <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
                  <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Username</mat-label>
                    <input matInput formControlName="username" required>
                    <mat-icon matSuffix>face</mat-icon>
                    @if (form.get('username')?.hasError('required')) {
                    <mat-error>
                      Username is required
                    </mat-error>
                    }
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-12col-lg-12 col-md-12 col-sm-12 mb-2">
                  <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="email" required>
                    <mat-icon matSuffix>mail</mat-icon>
                    @if (form.get('email')?.hasError('required') || form.get('email')?.touched) {
                    <mat-error>
                      Please enter a valid email address
                    </mat-error>
                    }
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-12col-lg-12 col-md-12 col-sm-12 mb-2">
                  <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Password</mat-label>
                    <input matInput formControlName="password" [type]="hide ? 'password' : 'text'" required>
                    <mat-icon matSuffix (click)="hide = !hide">
                      {{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    @if (form.get('password')?.hasError('required')) {
                    <mat-error>
                      Password is required
                    </mat-error>
                    }
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-12col-lg-12 col-md-12 col-sm-12 mb-2">
                  <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Confirm Password</mat-label>
                    <input matInput formControlName="cpassword" [type]="chide ? 'password' : 'text'" required>
                    <mat-icon matSuffix (click)="chide = !chide">
                      {{chide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    @if (form.get('cpassword')?.hasError('required')) {
                    <mat-error>
                      Confirm Password is required
                    </mat-error>
                    }
                  </mat-form-field>
                </div>
              </div>
              <div class="flex-sb-m w-100  p-b-20">
                <div>
                  <span>Already Registered? <a routerLink="/login">
                      Login
                    </a></span>
                </div>
              </div>
              <div class="container-auth-form-btn">
                <button mat-flat-button color="accent" class="auth-form-btn" [disabled]="!form.valid " type="submit">
                  Register
                </button>
              </div>
            </form>
            <h6 class="social-login-title">OR</h6>
            <ul class="list-unstyled social-icon mb-0 mt-3">
              <li class="list-inline-item"><a href="javascript:void(0)" class="rounded">
                  <i class="fab fa-google"></i>
                </a></li>
              <li class="list-inline-item"><a href="javascript:void(0)" class="rounded flex-c-m">
                  <i class="fab fa-facebook-f"></i>
                </a></li>
              <li class="list-inline-item"><a href="javascript:void(0)" class="rounded">
                  <i class="fab fa-twitter"></i>
                </a></li>
              <li class="list-inline-item"><a href="javascript:void(0)" class="rounded">
                  <i class="fab fa-linkedin-in"></i>
                </a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
</div>