<h1 mat-dialog-title >
    Choose Login Area
</h1>

<mat-dialog-content appScrolling >
    <div class="box-container mt-4 mb-4">
        <div class="square-box text-center" (click)="onSelect(1)" [ngClass]="{'selected': selectedBox == 1}">
            <div>
                <img class="rounded-full mx-auto" src="assets/icons/admin.png" alt="admin" style="width: 100px; height: 100px;">
                <br>
                <h5 class="mt-1">Admin/Org Users App</h5>
            </div>
        </div>
        <div class="square-box text-center" (click)="onSelect(2)" [ngClass]="{'selected': selectedBox == 2}">
            <div>
                <img class="rounded-full mx-auto" src="assets/icons/farmer.png" alt="farmer" style="width: 100px; height: 100px;">
                <br>
                <h5 class="mt-1">Growers App</h5>
            </div>
        </div>
    </div>
</mat-dialog-content>
