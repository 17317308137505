import { Injectable } from '@angular/core';
import { Router } from '@angular/router';  
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpResponse } from '@angular/common/http';

import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { switchMap, take, map, catchError, filter } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import * as fromAuth from '@core/store/reducers/auth.reducers';
import * as fromApp from '@core/store/app.state';
import * as AuthActions from '@core/store/actions/auth.actions';
import { AuthService } from '../services/auth.service';
import { LoginResponse } from '../modules/shared/interfaces/login-response.type';


/** Pass the request after appending origin url with protocol. */
@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {

  private refreshTokenInProgress: boolean | null = false;
  // Refresh Token Subject tracks the current token, or is null if no token is currently
  // available (e.g. refresh pending).
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private router: Router,
    private authService: AuthService,
    private store: Store<fromApp.AppState>
  ) { 

    this.store.select('auth').subscribe((authState: fromAuth.State) => {
      console.log('Refresh Token Interceptor: ',  authState);
      this.refreshTokenInProgress = authState.refreshTokenInProgress;
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse && (event.status / 100) > 3) {
          console.log(`HttpResponse:: Status: ${event.status}, \n event:`, event);
        }
        return event;
      }),
      catchError((error: any) => {
        if (error instanceof HttpErrorResponse) {

          console.log(`Error HTTP Response: Status: ${error.status}`);
          if (error.status === 403) {
            this.router.navigate(['admin/access-denied'])
          }

          // We don't want to refresh token for some requests like login or refresh token itself
          // So we verify url and we throw an error if it's the case
          if (req.url.includes('entrance/refresh-login-token') || req.url.includes('entrance/login') || req.url.includes('entrance/login-as-oauth-client')) {
            // We do another check to see if refresh token failed
            // In this case we want to logout user and to redirect it to login page
            if (req.url.includes('entrance/refresh-login-token')) {
              this.authService.logout();
            }
            return throwError(error);
          }

          if (error.status == 401) {
            return this.handle401Error(req, next);
          }        
          
          return throwError(error);
          
        }
        return throwError(error);
      }));

  }


  private handle401Error(req: HttpRequest<any>, next: HttpHandler) {

    console.log(`req.url:`, req.url);
    console.log(`this.refreshTokenInProgress:`, this.refreshTokenInProgress);

    if(!this.refreshTokenInProgress) {
      this.store.dispatch(AuthActions.refreshTokenInProgress({ isAuthenticated: false}));
      // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
      console.log(`Starting Refresh Token Process....`);
      this.refreshTokenSubject.next(null);

      // Call auth.refreshAccessToken(this is an Observable that will be returned)
      return this.authService.refreshAccessToken().pipe(
        switchMap((tokenData: LoginResponse) => {
           // When the call to refreshToken completes we reset the refreshTokenInProgress to false
          // for the next time the token needs to be refreshed
          this.refreshTokenSubject.next(tokenData);
          this.store.dispatch(AuthActions.refreshTokenSuccess(tokenData));
          const authenticatedReq = this.sendAuthenticatedRequest(req, tokenData);
          console.log('authenticatedReq 1:', authenticatedReq);
          return next.handle(authenticatedReq);
        }),
        catchError((err) => {
          this.authService.logout();
          return throwError(err);
        })
      );
    }

    // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
    // – which means the new token is ready and we can retry the request again
    return this.refreshTokenSubject.pipe(
      filter(tokenData => tokenData !== null),
      take(1),
      switchMap((tokenData) => {
        console.log(`req.url filter period over for, going to send authenticated request:`, req.url);
        const authenticatedReq = this.sendAuthenticatedRequest(req, tokenData);
        console.log('authenticatedReq 2:', authenticatedReq);
        return next.handle(authenticatedReq);
      })
    );

  }

  private sendAuthenticatedRequest(req: HttpRequest<any>, tokenData: LoginResponse) {
    console.log('Sending the Pending Requests, After Access Token Refresh:', req.url);
    return req.clone({
      headers: req.headers.set('Authorization', 'Bearer ' + tokenData.accessToken)
    });
  }

}
